import React, { useState, useMemo, useCallback } from 'react';
import {
  Button,
  Link,
  IconButton,
  FormFieldGroup,
  View,
  Text,
  List,
  Flex,
  IconAdminLine,
  IconEyeLine,
} from '@instructure/ui';
import TextField from 'components/IUTextField';
import * as Routes from 'Routes';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { PasswordSchema, MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from './passwordSchema';

interface IUPasswordFieldsProps {
  backToLogin?: boolean;
  buttonText?: string;
  panelMinHeight?: string;
  submitButton?: boolean;
}

const IUPasswordFields: React.FC<IUPasswordFieldsProps> = ({
  backToLogin = false,
  buttonText = 'Set Password',
  panelMinHeight = '32em',
  submitButton = true,
}) => {
  const [password, updatePassword] = useState<string>('');
  const [confirmPassword, updateConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // Here use debounce will only return the new password value if it hasn't changed for 0.5 seconds, or any other timeout that you can provide as the second parameter for useDebouncedValue
  // Once the user stops typing and debouncedPassword is updated with the new password value, only then useMemo will run validation and return the updated password error message
  const debouncedPassword = useDebouncedValue(password);
  const validationMessage = useMemo(() => {
    try {
      PasswordSchema.validateSync(debouncedPassword);
      return '';
    } catch (err) {
      return (err as Error).message;
    }
  }, [debouncedPassword]);

  const isValidPassword = useMemo(() => validationMessage === '', [debouncedPassword]);

  // Validate the actual password (not debounced) for form submission
  const validateRealPassword = useCallback(() => {
    try {
      PasswordSchema.validateSync(password);
      return true;
    } catch (err) {
      return false;
    }
  }, [password]);

  // Check if passwords match using actual values (not debounced)
  const passwordsMatch = useMemo(() => password === confirmPassword, [password, confirmPassword]);

  // For actual form submission, we validate the real password and match
  const isSubmissionAllowed = useMemo(
    () => !!password && !!confirmPassword && validateRealPassword() && passwordsMatch,
    [password, confirmPassword]
  );

  const renderFields = () => {
    return (
      <>
        <View
          as="div"
          display="inline-block"
          margin="medium none none none"
          padding="small"
          background="secondary"
          borderWidth="small"
          borderColor="primary"
          textAlign="center"
        >
          <Text weight="bold">Password Criteria</Text>
          <View as="div" textAlign="start" margin="small none none none">
            <List margin="none">
              <List.Item>
                Must be {MIN_PASSWORD_LENGTH}-{MAX_PASSWORD_LENGTH} characters in length.
              </List.Item>
              <List.Item>
                Must include at least 1 uppercase character, 1 lowercase character, 1 digit and 1 symbol.
              </List.Item>
            </List>
          </View>
        </View>
        <FormFieldGroup description="">
          <TextField
            data-node="password"
            onChange={e => updatePassword(e.target.value)}
            name="user[password]"
            value={password}
            id="user_password"
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            autoComplete="new-password"
            variant="filled"
            error={!isValidPassword}
            helperText={!isValidPassword ? validationMessage : 'Required*'}
            renderAfterInput={
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                shape="circle"
                size="small"
                withBorder={false}
                withBackground={false}
                screenReaderLabel="Show password"
                renderIcon={showPassword ? IconAdminLine : IconEyeLine}
                data-node="login-show-password-button"
              />
            }
          />
          <TextField
            data-node="confirm_password"
            onChange={e => updateConfirmPassword(e.target.value)}
            name="user[password_confirmation]"
            value={confirmPassword}
            id="user_confirm_password"
            label="Confirm New Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            autoComplete="new-password"
            variant="filled"
            error={!!confirmPassword && !passwordsMatch}
            helperText={!!confirmPassword && !passwordsMatch ? 'Passwords must match' : 'Required*'}
            renderAfterInput={
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                shape="circle"
                size="small"
                withBorder={false}
                withBackground={false}
                screenReaderLabel="Show password"
                renderIcon={showPassword ? IconAdminLine : IconEyeLine}
                data-node="login-show-password-button"
              />
            }
          />
          {backToLogin && (
            <div>
              <Link isWithinText={false} href={Routes.new_user_session_path()}>
                Back to Login
              </Link>
            </div>
          )}
        </FormFieldGroup>
      </>
    );
  };

  if (submitButton) {
    return (
      <>
        <Flex.Item shouldShrink shouldGrow>
          <View as="div" margin="none" minHeight={panelMinHeight}>
            {renderFields()}
          </View>
        </Flex.Item>
        <Flex.Item>
          <Flex as="div" margin="none" direction="row">
            <Flex.Item shouldShrink shouldGrow />
            <Flex.Item>
              <Button
                data-node="password_button"
                interaction={!isSubmissionAllowed ? 'disabled' : 'enabled'}
                color="primary"
                margin="xx-small"
                size="medium"
                type="submit"
              >
                {buttonText}
              </Button>
            </Flex.Item>
          </Flex>
        </Flex.Item>
      </>
    );
  }

  return renderFields();
};

export default IUPasswordFields;
